/*
 * 업무구분: 고객
 * 화 면 명: MSPCM020M
 * 화면설명: 인증화면 메인(카카오, 휴대폰, 신용카드)
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.06.14
 * 작 성 자: 이태훈
 * 2차 작성자: sh.park2022
 */
<template>
  <ur-box-container>

    
    <!-- (Kakao:카카오, MobilePhone:휴대폰, CreaditCard:신용카드) -->
    <!-- 고객 등록_카카오 인증 -->
    <MSPCM006D v-if="authSlctPage === 'Kakao'" :infoPrcusCnsntTypCd="infoPrcusCnsntTypCd" @authCompleted="fn_AuthCompleted"></MSPCM006D>
    
    <!-- 고객 등록_휴대폰 인증 -->
    <MSPCM008D v-else-if ="authSlctPage === 'MobilePhone'" :infoPrcusCnsntTypCd="infoPrcusCnsntTypCd" :mktChkList="mktChkList" 
              :mktCnsntAvlPrd="mktCnsntAvlPrd" @authCompleted="fn_AuthCompleted"></MSPCM008D>

    <!-- 고객 신용카드-->
    <MSPCM011D v-else :infoPrcusCnsntTypCd="infoPrcusCnsntTypCd" @authCompleted="fn_AuthCompleted"></MSPCM011D>


    <div>
      <!-- 이용동의 안내 bottomSheet -->
      <mo-bottom-sheet ref="underDMT3Modal" class="ns-bottom-sheet ns-style3">
        <div>
        <p class="cancel-ment" v-html="modalMsg"></p>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CloseModal()">동의종료</mo-button>
              <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_MoveMspcm012mPage()">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>
    </div>
  </ur-box-container>
</template>
<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import moment from 'moment'
import Msg from '@/systems/webkit/msg/msg'
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import MSPCM006D from '@/ui/cm/MSPCM006D.vue'
import MSPCM008D from '@/ui/cm/MSPCM008D.vue'
import MSPCM011D from '@/ui/cm/MSPCM011D.vue'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM020M',
  // 현재 화면 ID
  screenId: 'MSPCM020M',
  // 컴포넌트 선언
  components: {
    moment,
    MspBottomSelect,
    MSPBottomAlert,
    MSPCM006D,
    MSPCM008D,
    MSPCM011D
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      title: '인증화면 메인', // 화면명
      userInfo: {},
      custCardVO: {},
      agreeTypeMcnstList: {}, // 필수컨설팅 동의 리스트
      agreeTypeMktList: {}, // 마케팅 동의 리스트
      mktChkList: [], // 마컨 정보 권유방식 (광고성 수신동의) ['P', 'S', 'M', 'E']
      mktCnsntAvlPrd: '', // 마컨 정보(개인정보 보유이용기간)
      infoPrcusCnsntTypCd: '', // 정보활용동의 유형코드(17: 필수컨설팅, 19: 마케팅동의, 18: 둘다)
      authSlctPage : '', // 인증 목록 선택 페이지
      lmsDspchRsltId: '', // LMS발송결과ID
      athntHstrId: '', // 카카오인증이력ID
      lv_cnsltNo: '',  // 컨설턴트 ID

      pCustNm: '',
      modalMsg : '',
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    underDMT3Modal () {
      return this.$refs.underDMT3Modal
    },
  },
  
  /** watch 정의 영역 */
  watch: {},

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.lv_cnsltNo = this.userInfo.userId 

    this.pCustNm = !this.$bizUtil.isEmpty(this.$route.params.custNm) ? this.$route.params.custNm : ''
	  if(!_.isEmpty(this.$route.params)){
      this.agreeTypeMcnst = this.$route.params.agreeTypeMcnst, //필컨 정보 여부
      this.mktChkList = this.$route.params.mktChkList, //마컨 정보(광고성 수신동의)
      this.mktCnsntAvlPrd = this.$route.params.mktCnsntAvlPrd, //마컨 정보(개인정보 보유이용기간)
      this.infoPrcusCnsntTypCd = this.$route.params.infoPrcusCnsntTypCd, //필컨만 : 17, 마동만 : 19, 필컨, 마동 둘다 : 18
      this.authSlctPage = this.$route.params.authSlctPage //인증 목록 선택 페이지
	  } else {
      // 고객 정보
      let storeCustInfo = window.vue.getStore('cmStore').getters.getState.custInfo

      this.infoPrcusCnsntTypCd = storeCustInfo.infoPrcusCnsntTypCd
      // 필동
      this.agreeTypeMcnst = storeCustInfo.agreeTypeMcnst

      // 마동
      if (!_.isEmpty(storeCustInfo.mktChkList)) {
        this.mktChkList = storeCustInfo.mktChkList
        this.mktCnsntAvlPrd = storeCustInfo.mktCnsntAvlPrd

        this.pCustNm = storeCustInfo.custNm
      }
      // 인증 목록 선택 페이지
      this.authSlctPage = storeCustInfo.authSlctPage
    }

    this.$BottomManager.fn_SetBottomVisible(false)
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    this.$bizUtil.insSrnLog('MSPCM020M')
  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    this.$BottomManager.fn_SetBottomVisible(true)
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_AuthCompleted
    * 설명       : 인증 완료 후 호출됨. - 정보활용동의 저장 VO세팅
    ******************************************************************************/
    fn_AuthCompleted (param) {
      this.fn_BindCustAgreeInfo(param) // 정보활용동의 저장하기 위한 VO세팅 // 테스트용 주석처리
    },
    /******************************************************************************
    * Function명 : fn_BindCustAgreeInfo
    * 설명       : 인증 완료 후 정보활용동의 정보 VO 매핑 - 인증 완료 후 호출됨.
    ******************************************************************************/
    fn_BindCustAgreeInfo (param) {
      this.custCardVO = param.custCardVO
      this.custCardVO.infoPrcusCnsntTypCd = this.infoPrcusCnsntTypCd

      if (this.infoPrcusCnsntTypCd === '17' || this.infoPrcusCnsntTypCd === '18') { // 필컨만 또는 필컨+마동일때 필컨값 셋팅
        // 필수 컨설팅 세팅
        this.agreeTypeMcnstList.infoPrcusCnsntPathCd = param.infoPrcusCnsntPathCd // 활용동의 경로 활용동의 경로(01:영업채널 , 02:모바일, 03:신용카드, 04:모바일(사랑온) 14:휴대폰사랑On미니 15:신용카드사랑On미니 16:카카오Pay사랑On미니)
        this.agreeTypeMcnstList.aprvNo = (param.infoPrcusCnsntPathCd === '14') ? param.aprvNo : '' // 인증번호(휴대폰사랑On미니 경우에만 해당됨)
      }

      if (this.infoPrcusCnsntTypCd === '19' || this.infoPrcusCnsntTypCd === '18') { // 마동만 또는 필컨+마동일때 마동값 셋팅
      // 마케팅동의 세팅
        let lv_MktChkList = this.mktChkList
        let lv_MktCnsntAvlPrd = this.mktCnsntAvlPrd

        this.agreeTypeMktList.mktCnvsMthTelYn = (lv_MktChkList.find(item => item === 'P')) ? 'Y' : 'N'
        this.agreeTypeMktList.mktCnvsMthSmsYn = (lv_MktChkList.find(item => item === 'S')) ? 'Y' : 'N'
        this.agreeTypeMktList.mktCnvsMthPstYn = (lv_MktChkList.find(item => item === 'M')) ? 'Y' : 'N'
        this.agreeTypeMktList.mktCnvsMthEmailYn = (lv_MktChkList.find(item => item === 'E')) ? 'Y' : 'N'
        this.agreeTypeMktList.mktCnsntAvlPrd = lv_MktCnsntAvlPrd // 기간
        if (param.infoPrcusCnsntPathCd !== undefined) {
          this.agreeTypeMktList.infoPrcusCnsntPathCd = param.infoPrcusCnsntPathCd // 활용동의 경로 활용동의 경로(01:영업채널 , 02:모바일, 03:신용카드, 04:모바일(사랑온))
          this.agreeTypeMktList.aprvNo = (param.aprvNo !== undefined) ? param.aprvNo : '' // 인증번호(모바일일 경우에만 해당됨)
        }
      }
      //기존회원일 경우만 3개월 내 조회
      if(this.custCardVO.isExistCustYN === 'Y'){
        this.fn_SearchCustCnsntInfo()
      }else{
        this.fn_MoveMspcm012mPage()
      }
    },
    /*********************************************************
     * Function명: fn_SearchCustCnsntInfo
     * 설명: 고객 동의 정보 조회 (3 개월 이내 동의 내역 체크)
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_SearchCustCnsntInfo () {
      // 인증단계에서 주민번호 수집이 안되서, 안함 2023.05.25 황고은 프로/이상인 프로 확인 완료
      this.fn_MoveMspcm012mPage()
      return
      // if ( this.lv_cnsltNo.trim() && (typeof this.custCardVO !== 'undefined' && this.custCardVO.custNm.trim()) ) {
      //   let lv_Vm = this
      //   let items = null//조회결과
      //   let trnstId = 'txTSSCM82S3'
      //   let pParams = {}
      //   let msg = ''
      //   let bfMcnstFlg = false // 이전 필수컨설팅 여부
      //   let bfMktFlg = false // 이전 마케팅 여부
      //   let bfMCnstYmd = '' // 이전 필수컨설팅 동의 날짜
      //   let bfMktYmd = ''  // 이전 마케팅 동의 날짜


      //   pParams.custNm = this.custCardVO.custNm // 고객명
      //   pParams.cnsltNo = this.lv_cnsltNo // 컨설턴트 ID
      //   pParams.infoPrcusCnsntStatCd = '01' // 정보활용동의 처리상태코드 - 01 : 신청 , 02 : 승인 , 03 : 승인취소
      //   pParams.chnlCustId = this.custCardVO.chnlCustId // 채널고객ID
      //   pParams.chnlCstgrId = this.custCardVO.chnlCstgrId // 채널그룹ID
      //   pParams.infoPrcusCnsntTypCd =  this.infoPrcusCnsntTypCd // 정보활용동의 유형 - 17 : 필동 , 18 : 필동+마동 , 19 : 마동
      //   pParams.infoPrcusCnsntPathCd = '18' // 정보활용동의경로코드 - 01 : 동의서 , 02 : 휴대폰 , 03 : 신용카드 ... 17 : 사랑On영업관리자승인 , 18 : 사랑On미니영업관리자승인
        
      //   // 고객검색
      //   this.post(lv_Vm, pParams, trnstId, 'S')
      //     .then(function (response) {
      //         if (response.body !== null && response.body.iCCustInfoPrcusCnsntVO !== null) {
      //           lv_Vm.custCardVO.iCCustInfoPrcusCnsntVO = response.body.iCCustInfoPrcusCnsntVO
                
      //           if (response.body.iCCustInfoPrcusCnsntVO.length > 0) {
      //             items = response.body.iCCustInfoPrcusCnsntVO[0] //단건만 

      //             if (response.body.iCCustInfoPrcusCnsntVO.length > 1) {
      //               for (let i = 0; i < response.body.iCCustInfoPrcusCnsntVO.length; i++) {
      //                 if (response.body.iCCustInfoPrcusCnsntVO[i].chnlCustId === lv_Vm.custCardVO.chnlCustId) {
      //                   items = response.body.iCCustInfoPrcusCnsntVO[i] //단건만 
      //                   break
      //                 }
      //               }
      //             }

      //             // 필수컨설팅&전체 날짜 체크
      //             if(lv_Vm.infoPrcusCnsntTypCd === '17' || lv_Vm.infoPrcusCnsntTypCd === '18'){
      //               if (!lv_Vm.fn_PrcusCnsntAprovAbleChk(items.infoPrcusCnsntYmd13, items.infoPrcusCnsntYmd14)) {
      //                 bfMcnstFlg = true
      //                 bfMCnstYmd = items.infoPrcusCnsntYmd13.substring(0, 4) + '-' + items.infoPrcusCnsntYmd13.substring(4, 6) + '-' + items.infoPrcusCnsntYmd13.substring(6)
      //               }
      //             }
                
      //             // 마케팅동의&전체 날짜 체크
      //             if ( lv_Vm.infoPrcusCnsntTypCd === '19' || lv_Vm.infoPrcusCnsntTypCd === '18') {
      //               if (!lv_Vm.fn_PrcusCnsntAprovAbleChk(items.infoPrcusCnsntYmd11, items.infoPrcusCnsntYmd12)) {
      //                 bfMktFlg = true
      //                 bfMktYmd = items.infoPrcusCnsntYmd11.substring(0, 4) + '-' + items.infoPrcusCnsntYmd11.substring(4, 6) + '-' + items.infoPrcusCnsntYmd11.substring(6)
      //               }
      //             }
                  
      //             //메시지 처리
      //             if(lv_Vm.infoPrcusCnsntTypCd === '17'){
      //               //필컨 
      //               if(bfMcnstFlg) msg = items.custNm + ' 고객님은 최근 3개월 내 필수 컨설팅 (' + bfMCnstYmd + ') 를 받아 추가 동의는 불가합니다.<br/> 세대원에 대한 동의만 진행 하시겠습니까?' 

      //             }else if ( lv_Vm.infoPrcusCnsntTypCd === '18' ) {
      //               //전체동의
      //               if(bfMcnstFlg && bfMktFlg) msg = items.custNm + ' 고객님은 최근 3개월 내 <br/>필수 컨설팅 (' + bfMCnstYmd + ') 및 마케팅 동의 (' + bfMktYmd + ') 를 받아 추가 동의는 불가합니다.<br/> 세대원에 대한 동의만 진행 하시겠습니까?'
      //               if(bfMcnstFlg && !bfMktFlg) msg = items.custNm + ' 고객님은 최근 3개월 내 필수 컨설팅 (' + bfMCnstYmd + ') 를 받아 추가 동의는 불가합니다. 세대원에 대한 동의만 진행 하시겠습니까?'
      //               if(!bfMcnstFlg && bfMktFlg) msg = items.custNm + ' 고객님은 최근 3개월 내 마케팅 동의 (' + bfMktYmd + ') 를 받아 추가 동의는 불가합니다. 세대원에 대한 동의만 진행 하시겠습니까?'

      //             }else if ( lv_Vm.infoPrcusCnsntTypCd === '19' ) {
      //               //마컨
      //               if(bfMktFlg) msg = items.custNm + ' 고객님은 최근 3개월 내 마케팅 동의 (' + bfMktYmd + ') 를 받아 추가 동의는 불가합니다. 세대원에 대한 동의만 진행 하시겠습니까?'
      //             }


      //             // 팝업 처리
      //             if (msg !== '') {
      //               // 모달 창 오픈
      //               lv_Vm.modalMsg = msg
      //               lv_Vm.underDMT3Modal.open()
      //             }else{
      //               //3개월 이내 해당 사항 없음
      //               lv_Vm.fn_MoveMspcm012mPage()
      //             }

      //           }else{
      //             //3개월 이내 해당 사항 없음
      //             lv_Vm.fn_MoveMspcm012mPage()
      //           }
      //         }else{
      //           console.log("fn_SearchCustCnsntInfo response.body() 실행 중 에러 발생 ")
      //         }
      //     })
      //     .catch(function (error) {
      //       window.vue.error(error)
      //     })
      // } else {
      //   console.log("fn_SearchCustCnsntInfo() 실행 중 에러 발생 : cnsltNo["+this.lv_cnsltNo+"], this.custCardVO >"+JSON.stringify(this.custCardVO))
      // }
    },
    /*********************************************************
     * Function명: fn_PrcusCnsntAprovAbleChk
     * 설명: 필수동의 마케팅동의 재동의 날자 체크
     * Params: N/A
     * Return: result - true / false
     *********************************************************/
    fn_PrcusCnsntAprovAbleChk (strAvlStrYmd, strAvlEndYmd) {
      if (strAvlStrYmd === null || typeof strAvlStrYmd === 'undefined' || strAvlStrYmd.length === 0) return true
      if (strAvlEndYmd === null || typeof strAvlEndYmd === 'undefined' || strAvlEndYmd.length === 0) return true

      let s = strAvlStrYmd.trim().replace(/-/gi, '').replace(/00000000/gi, '') || ''
      let e = strAvlEndYmd.trim().replace(/-/gi, '').replace(/00000000/gi, '') || ''
      let n = moment(new Date()).format('YYYYMMDD')
      if (s === '' || e === '') {
        return true
      } else {
        if (e === '99991231') {
          return false
        } else {
          // 시작일 + 2달
          let y = s.substring(0, 4)
          let m = s.substring(4, 6)
          let tmp = Number(m) + 2 // 시작일 +2달 체크
          if (tmp < 10) {
            m = '0' + tmp
          } else if (tmp < 13) {
            m = '' + tmp
          } else if (tmp > 12) {
            m = '0' + (tmp - 12)
            y = '' + (Number(y) + 1)
          }
          s = y + m + '01'
          if (Number(s) <= Number(n)) {
            return true
          } else {
            return false
          }
        }
      }
    },
    /******************************************************************************
     * Function명 : fn_CloseModal
     * 설명       : 모달 창 닫기 및 안내문구
     ******************************************************************************/
    fn_CloseModal () {
      this.underDMT3Modal.close()
      this.fn_ConfirmOpen()
    },
    /******************************************************************************
    * Function명 : fn_MoveMspcm001mPage
    * 설명       : MSPCM001M화면으로 이동 (고객동의 목록)
    ******************************************************************************/
    fn_MoveMspcm001mPage () {
      return
      this.$router.push({name: 'MSPCM001M'})
    },
    /******************************************************************************
    * Function명 : fn_MoveMspcm012mPage
    * 설명       : MSPCM012M화면으로 이동 (고객정보입력)
    ******************************************************************************/
    fn_MoveMspcm012mPage () {
      // Store 페이지 카운트
      let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
      window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt+1)

      // 현재 화면을 history 배열에 저장
      this.$commonUtil.setLastScreen(this.$options.screenId)
      this.$router.push({
            name: 'MSPCM012M',
            params: {
              name: 'MSPCM020M',
              custCardVO: this.custCardVO,
              agreeTypeMcnstList: this.agreeTypeMcnstList,
              agreeTypeMktList: this.agreeTypeMktList,
              infoPrcusCnsntTypCd: this.infoPrcusCnsntTypCd,
              lmsDspchRsltId: this.lmsDspchRsltId,
              athntHstrId: this.athntHstrId,
              custNm: this.pCustNm
            }
          })
    },
    /******************************************************************************
     * Function명 : fn_ConfirmOpen
     * 설명       : confirm창 오픈 함수
     *              confirm 창이 열릴경우 vuex에 열림 표시를 저장한다.
     ******************************************************************************/
    fn_ConfirmOpen () {
      let contentMsg = '고객동의를 종료하시겠습니까?<br/> 동의종료 선택시 작성된 고객정보는 저장되지 않으며, 고객동의는 완료되지 않습니다.'
      let preSrnObj = window.vue.getStore('cmStore').getters.getState.preSrnObjCM050M
      let preSrnId = !this.$bizUtil.isEmpty(preSrnObj.srnId) ? preSrnObj.srnId : 'MSPBC002M'

      this.alertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          //single: true,
          title: "",
          content: contentMsg,
          title_pos_btn: "나가기",
          title_neg_btn: "취소"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.alertPop);

            let preSrnObj = window.vue.getStore('cmStore').getters.getState.preSrnObjCM050M
            let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M

            window.vue.getStore('cmStore').dispatch('PRE_SRN_OBJ_CM050M', {}) // 진입전 정보 초기화

            if (_.isEmpty(preSrnObj.rtnParam)) {
              let storeCustInfo = window.vue.getStore('cmStore').getters.getState.custInfo
              // 필동 정보 초기화
              storeCustInfo.infoPrcusCnsntTypCd = ''
              storeCustInfo.agreeTypeMcnst = false
              // 마동 정보 초기화
              storeCustInfo.mktChkList = ''
              storeCustInfo.segmentType1 = ''
              storeCustInfo.mktCnsntAvlPrd = ''

              window.vue.getStore('cmStore').dispatch('ADD', storeCustInfo)
              this.$router.go(-(pageCnt+1))
            } else {
              this.$router.push({ name: preSrnObj.srnId, params: preSrnObj.rtnParam })
            }
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.alertPop);
          }
        }
      })
    },

  } //methods end
}
</script>
